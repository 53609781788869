.App {
  background-color: #333;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

.button-container {
  text-align: center;
}

.button {
  background-color: #599cc9;
  color: #fff;
  border: none;
  padding: 20px 40px;
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}

.button:hover {
  background-color: #599cc9;
}

.button-icon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  margin: 0 auto 20px;
}

.button-icon-img {
  width: 100%;
  height: 100%;
}
